import React, { useState, useEffect } from 'react'
import Marker from '../marker/marker'
import Footer from "../footer/footer"
import Header from "../header/header"
import Home from '../formRequestHome/formRequestHome'
import { Context } from '../context/provider'
import UploadDocuments from '../uploadDocuments/uploadDocuments'
import UploadSummary from '../uploadSummary/uploadSummary'
import { getStorageItem } from '../../utilities/getStorageItem'
import { useSiteConfig } from '../layout/getSiteConfig'

export default function DisabilityPlacard() {
  const { disabilityPlacardInfo } = useSiteConfig()

  const [current, setCurrent] = useState(getStorageItem('mcn-form-filled') === 'true' ? 1 : '');

  useEffect(() => {
    if (typeof window != 'undefined') {
      window.scrollTo(0,0)
    }
  }, [current])

  useEffect(() => {
    if (typeof window != 'undefined' && window.location.search.includes('test=')) {
      const search = window.location.search
      const page = search.split('=')[1]
      setCurrent(parseInt(page))
    }
  }, [])

  const markerItems = [
    'Upload documents',
    'Summary',
    'Payment'
  ]

  useEffect(() => {
    if (current === 2) {
      window.location.href = '/payment'
    }
  }, [current])

  const formName = 'Handicap Placard'
  const formFile = '/disabilities-placard.pdf'
  const description = 'Have your doctor or health care provider complete the Disability Statement Section of the application. There is a $7 fee.'
  const fee = 7
  const formPath = 'disability-placard'
  const infoApi = disabilityPlacardInfo

  return (
    <Context.Provider value={{ current, setCurrent }}>
      <Header title={parseInt(current) >= 0 ? formName : ''} />
      {parseInt(current) >= 0 && current <= 2 && <Marker items={markerItems}/>}
      {current === '' && <Home form={formFile} label={formName} description={description} />}
      {current === 0 && <UploadDocuments fee={fee} formName={formName} includeTag={false} />}
      {current === 1 && <UploadSummary {...{ formName, formPath, infoApi }} />}
      <Footer />
    </Context.Provider>
  )
}
