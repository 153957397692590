import React from "react"
import DisabilityPlacard from "../components/disabilityPlacard/disabilityPlacard";
import Layout from "../components/layout/layout"

export default function ReplaceDecal() {
  return (
    <Layout>
      <DisabilityPlacard />
    </Layout>
  );
}
